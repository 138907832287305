import { lock, clearBodyLocks } from 'tua-body-scroll-lock';
import ScrollMagic from 'scrollmagic';

export default {
    init() {
        //////////// Navigation
        const body = document.body;
        const header = document.getElementById('header-menu') as HTMLElement;
        const controller = new ScrollMagic.Controller();

        if (!header) {
            return;
        }

        const burgerButton = header.querySelector(
            '.menu-burger'
        ) as HTMLElement;
        const menuNavigation = header.querySelector(
            '.menu-navigation'
        ) as HTMLElement;
        const menuNavItem = header.querySelectorAll('.menu-nav-item');
        const menuNavbar = header.querySelector('.menu-navbar');
        let secondLayerMenu: HTMLElement;

        if (!burgerButton || !menuNavigation || !body || !menuNavbar) {
            return;
        }

        const resetMenu = () => {
            if (!secondLayerMenu) {
                return;
            }
            secondLayerMenu.classList.add('no-animations');
            secondLayerMenu.classList.add('translate-x-full');
            const thirdLayerMenus = Array.from(
                secondLayerMenu.querySelectorAll('.menu-nav-third-layer')
            );

            thirdLayerMenus.forEach((thirdLayerMenu) => {
                thirdLayerMenu.classList.add('translate-x-full');
                secondLayerMenu.classList.remove('overflow-hidden');
            });

            menuNavigation.classList.remove('overflow-hidden');
            const timeoutID = window.setTimeout(() => {
                secondLayerMenu.classList.remove('no-animations');
                window.clearTimeout(timeoutID);
            }, 100);
        };

        // Remove class that blocks CSS transitions in header. The class is needed because css animations trigger on load and we don't want it :)
        document.addEventListener('DOMContentLoaded', () => {
            header.classList.remove('no-animations');
        });

        // Set active menu on burger click
        burgerButton.addEventListener('click', () => {
            header.classList.toggle('active-navigation');

            if (header.classList.contains('active-navigation')) {
                //open
                lock(menuNavigation);
                resetMenu();
            } else {
                //close
                clearBodyLocks();
            }
        });

        // Close menu by clicking out of it
        const backdrop = header.querySelector('.header-menu-backdrop');

        if (backdrop) {
            backdrop.addEventListener('click', () => {
                header.classList.remove('active-navigation');
                clearBodyLocks();
            });
        }

        if (menuNavItem.length == 0) {
            return;
        }

        // Handle second layer of products mobile
        menuNavItem.forEach((navItem) => {
            secondLayerMenu = navItem.querySelector(
                '.menu-nav-second-layer'
            ) as HTMLElement;

            const thirdLayerMenus = Array.from(
                secondLayerMenu?.querySelectorAll('.menu-nav-third-layer') ?? []
            );

            if (thirdLayerMenus.length > 0) {
                thirdLayerMenus.forEach((thirdLayerMenu) => {
                    thirdLayerMenu.classList.add('translate-x-full');

                    const backButton = thirdLayerMenu.querySelector(
                        '.btn-back'
                    ) as HTMLElement;

                    if (!backButton) {
                        return;
                    }

                    const subNavItems = Array.from(
                        secondLayerMenu.querySelectorAll('.submenu')
                    );

                    subNavItems.forEach((subNavItem) => {
                        const button =
                            subNavItem.querySelector('.menu-nav-btn');

                        if (!button) {
                            return;
                        }

                        button.addEventListener('click', () => {
                            const thirdLayerMenus = subNavItem.querySelector(
                                '.menu-nav-third-layer'
                            ) as HTMLElement;

                            thirdLayerMenus?.classList.remove(
                                'translate-x-full'
                            );
                            secondLayerMenu.classList.add('overflow-hidden');

                            backButton.addEventListener('click', (e) => {
                                e.stopPropagation();
                                thirdLayerMenus?.classList.add(
                                    'translate-x-full'
                                );
                                secondLayerMenu.classList.remove(
                                    'overflow-hidden'
                                );
                            });
                        });
                    });
                });
            }

            navItem.addEventListener('click', () => {
                secondLayerMenu = navItem.querySelector(
                    '.menu-nav-second-layer'
                ) as HTMLElement;

                if (!secondLayerMenu) {
                    return;
                }

                const backButton = secondLayerMenu.querySelector(
                    '.btn-back'
                ) as HTMLElement;

                if (!backButton) {
                    return;
                }

                secondLayerMenu.classList.remove('translate-x-full');
                menuNavigation.classList.add('overflow-hidden');

                backButton.addEventListener('click', (e) => {
                    e.stopPropagation();
                    secondLayerMenu.classList.add('translate-x-full');
                    menuNavigation.classList.remove('overflow-hidden');
                });
            });
        });

        new ScrollMagic.Scene({
            offset: 100
        })
            .setClassToggle(header, 'header-scrolled')
            .addTo(controller);

        new ScrollMagic.Scene({
            offset: 100
        })
            .setClassToggle(menuNavbar, 'menu-scrolled')
            .addTo(controller);
    }
};
